<form
  class="spot-modal op-datepicker-modal loading-indicator--location"
  data-qa-selector="op-datepicker-modal"
  [attr.id]="htmlId"
  #modalContainer
  data-indicator-name="modal"
  (submit)="save($event)"
>
  <op-datepicker-banner [scheduleManually]="scheduleManually"></op-datepicker-banner>

  <div class="spot-modal--body spot-container">
    <div class="op-datepicker-modal--toggle-actions-container">
      <op-datepicker-scheduling-toggle
        name="scheduleManually"
        [(ngModel)]="scheduleManually"
        (ngModelChange)="changeSchedulingMode()"
      ></op-datepicker-scheduling-toggle>
      <op-datepicker-working-days-toggle
        name="ignoreNonWorkingDays"
        [(ngModel)]="ignoreNonWorkingDays"
        (ngModelChange)="changeNonWorkingDays()"
      ></op-datepicker-working-days-toggle>
    </div>

    <div class="op-datepicker-modal--dates-container">
      <spot-form-field
        [label]="text.date"
        >
        <spot-text-field
          slot="input"
          name="date"
          class="op-datepicker-modal--date-field"
          [ngClass]="{ 'op-datepicker-modal--date-field_current': this.dateModalScheduling.isSchedulable }"
          [(ngModel)]="date"
          (ngModelChange)="dateChangedManually$.next()"
          [showClearButton]="true"
        ></spot-text-field>
        <button
          slot="action"
          type="button"
          class="spot-link"
          [ngClass]="{ 'op-datepicker-modal--hidden-link': !dateModalScheduling.isSchedulable }"
          (click)="setToday()"
          [textContent]="text.today">
        </button>
      </spot-form-field>
    </div>

    <input id="flatpickr-input"
           hidden>
  </div>

  <div class="spot-action-bar">
    <div class="spot-action-bar--right">
      <button
        (click)="cancel()"
        class="op-datepicker-modal--action button button_no-margin spot-action-bar--action"
        data-qa-selector="op-datepicker-modal--action"
        [textContent]="text.cancel"
      ></button>
      <button
        class="op-datepicker-modal--action button button_no-margin -highlight spot-action-bar--action"
        data-qa-selector="op-datepicker-modal--action"
        [textContent]="text.save"
      ></button>
    </div>
  </div>
</form>
